import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';



const ProjectFour = () => {
    return (
        <div className="section section-padding-equal ">
            <div className="container">
            <SectionTitle 
                title="Vårt arbeid."
                textAlignment="heading-light-left mb--90"
                textColor=""
            />
            <div className="project-add-banner">
                <div className="content">
                    <span className="subtitle">Utvikling av nettside</span>
                    <h3 className="title"><a href="https://klippenbergmedia.no" style={{color: '#ffffff'}}>Klippenbergmedia.no</a></h3>
                    <div className="brand-logo">
                        <img src={process.env.PUBLIC_URL + "/images/project/klippenbergmedia-white.png"} alt="Klippenberg Media"  style={{ maxHeight: 70}}/>
                    </div>
                </div>
                <div className="thumbnail">
                    <img src={process.env.PUBLIC_URL + "/images/project/klippenbergmedia-preview.png"} alt="klippenbergmedia.no" />
                </div>
            </div>
        </div>
    </div>
    )
}

export default ProjectFour;