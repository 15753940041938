import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import BlogGridOne from '../component/blog/BlogGridOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const BlogGridView = () => {

    return (
        <>
            <SEO title="Kunnskapsbasen" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />

                <div className="section-padding-equal">
                    <div className="container">
                       <div className="row row-40">
                            <div className="col-lg-8">
                                <h1>Kunnskapsbasen</h1>
                                <p className="text-secondary">Kunnskapsbasen er et sted der vi deler informasjon med våre kunder og partnere. Her legger vi ut veiledninger, artikler og annet nyttig innhold.</p>
                                <BlogGridOne />
                            </div>
                            <div className="col-lg-4">
                                <BlogSidebar />
                            </div>
                       </div>
                    </div>
                </div>
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default BlogGridView;