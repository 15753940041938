import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const TeamBanner = ({title, designation, mainThumb, paragraph, email}) => {
    return (
        <div className="banner banner-style-1 ">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">{title}</h1>
                                <h5 className="" >{designation}</h5>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    {mainThumb && (
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-info mb--30" style={{
                            background: 'rgba(255, 255, 255, 0.03)',
                            borderRadius: 16,
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                            backdropFilter: 'blur(5.1px)',
                            WebkitBackdropFilter: 'blur(5.1px)',
                            position: 'relative', // Set the position to relative for the icon to be positioned relative to this container
                        }}>      
                    
                            <h1 className="phone-number" ><a href={`mailto:${email}`}>{email}</a></h1>
                            <br></br>
                            <p dangerouslySetInnerHTML={{__html: paragraph}}>
                               
                            </p>   
                        </div>
                    </div>
                    )}

                </div>
            </div>
            
        </div>
    )
}

export default TeamBanner;
