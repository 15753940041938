import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';



const BannerOne = () => {
    return (
        <div className="banner banner-style-1 ">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">Vi utvikler programvare og nettsider for små og mellomstore bedrifter.</h1>
                                <span className="subtitle">Webutvikling, Apputvikling, Integrasjoner - Vi fikser det meste!</span>
        
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb">
                                    <a href="/lisenser" target="_blank">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/coding.png"} alt="Programmering" style={{ width: 900, marginTop: -68 }} />
                                    </a>
                                </div>
                            </AnimationOnScroll>

                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default BannerOne;