import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import PricingOne from '../component/pricing/PricingOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectFour from '../component/project/ProjectFour';
import AboutFive from '../component/about/AboutFive';
import HvorforVelgeOssProp from '../component/nionor/HvorforVelgeOssProp';
import SectionTitle from '../elements/section-title/SectionTitle';

const Webutvikling = () => {

    return (
        <>
        <SEO title="Nettsider" />
            <main className="main-wrapper">
                <HeaderOne />
                <br></br>
                <AboutFive readmore={false} />
                <div className="section some-cool-margin" > 
                    <div className="container">
                        <SectionTitle 
                            title="Hvorfor velge oss?"
                            description=""
                            textAlignment="heading-left mb--20 mb_md--70"
                            textColor=""
                        />
                            <div className="row">
                                <HvorforVelgeOssProp colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                            </div>
                        </div>

            </div>
                <div className="section some-cool-margin" >

                    <div className="container">
                    <SectionTitle 
                            title="Våre priser."
                            description=""
                            textAlignment="heading-right mb--20 mb_md--70"
                            textColor=""
                        />
                        <PricingOne />
                        <h5>*Priser er veiledende. Kontakt oss for å få et tilbud for din bedrift.</h5>
                        <p className="text-secondary">Priser oppgitt i norske kroner, eksludert MVA og andre offentlige avgifter.</p>
                    </div>
                </div>
                <ProjectFour />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Webutvikling;