import React from 'react';
import { Link } from 'react-router-dom';
import WidgetCategory from './widget/WidgetCategory';
import { FaInstagram} from "react-icons/fa";

const BlogSidebar = () => {
    return (
        <div className="axil-sidebar">
            <div className="widget widget-categories">
                <h4 className="widget-title">Kategorier</h4>
                <WidgetCategory />
            </div>
            <div className="widget widge-social-share">
                <div className="blog-share">
                    <h5 className="title">Følg oss:</h5>
                    <ul className="social-list list-unstyled">
                        <li><a href="https://www.instagram.com/nionorsoftware"><FaInstagram /></a></li>
                    </ul>
                </div>
            </div>
            <div className="widget widget-banner-ad">
                <Link to="#">
                    <img src={process.env.PUBLIC_URL + "/images/banner/branding.jpg"} alt="banner" />
                </Link>
            </div>
        </div>
    )
}

export default BlogSidebar;