import React from 'react';

const BlogAuthor = ({data}) => {
    return (
        <div className="blog-author bg-white">
            <div className="author">
                <div className="author-thumb" >
                    <img src={process.env.PUBLIC_URL + `/${data.author_avatar}`} alt="Blog Author"  style={{width: 120 }} />
                </div>
                <div className="info">
                    <h5 className="title" style={{color: "#0F443D",  fontSize: 35 }}>{data.author_name}</h5>
                    <p style={{color: "#0F443D"}}>{data.author_para}</p>
                </div>
                <div className="author-thumb" >
                    <img src={process.env.PUBLIC_URL + `/images/logo/icon.png`} alt="Nionor Software AS"  style={{width: 120 }} />
                </div>
            </div>
        </div>
    )
}

export default BlogAuthor;