import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './utils/elevator';

import Licenses from './pages/Licenses';
// Pages 
import DigitalAgency from './pages/DigitalAgency';

import AboutUs from './pages/AboutUs';
import TeamDetails from './pages/TeamDetails';
import WebUtvikling from './pages/WebUtvikling';
import TripletexIntegration from "./pages/TripletexIntegration";
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import ComingSoon from './pages/ComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BlogGrid from './pages/BlogGrid';
import BlogDetails from './pages/BlogDetails';
import BlogCategory from './pages/Category';

// Css Import
import './assets/scss/app.scss';


const App = () => {
  const isMaintenance = false;

  return (
    <Router>
		<ScrollToTop>
			<Routes>
			{isMaintenance ? (
				<Route path="/*" element={<ComingSoon />} />
			) : (
				<>
				<Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />} />
				{/* Rest of the routes */}
				
				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/om-oss"} element={<AboutUs />}/>
				<Route path={process.env.PUBLIC_URL + "/webutvikling"} element={<WebUtvikling />}/>
				<Route path={process.env.PUBLIC_URL + "/kunnskapsbase"} element={<BlogGrid />}/>
				<Route path={process.env.PUBLIC_URL + "/kunnskapsbase/:id"} element={<BlogDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/kategori/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/lisenser"} element={<Licenses />}/>
				<Route path={process.env.PUBLIC_URL + "/team-details/:slug"} element={<TeamDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/tripletex-integrasjon"} element={<TripletexIntegration />}/>
				<Route path={process.env.PUBLIC_URL + "/kontakt"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}/>
				<Route path="*" element={<ErrorPage />} />
				</>
			)}
			</Routes>
			
		</ScrollToTop>
    </Router>
  )
}

export default App;
