import React from 'react';
import { Link } from 'react-router-dom';
import TeamData from "../../data/team/TeamData.json";
import { slugify } from '../../utils';

const allData = TeamData;

const TeamOne = () => {
  let teamSizeClass = 'col-xl-3 col-sm-6';
  let imageSize = 'team-image';
  let teamItems = [];

  if (allData.length === 2) {
    teamSizeClass = 'col-lg-6 col-md-6 col-sm-12';
    imageSize = 'team-image-large';
  }

  teamItems = allData.map((data) => (
    <div className={teamSizeClass} key={data.id}>
      <div className="team-grid">
        <div className={`thumbnail ${imageSize}`}>
          <Link to={process.env.PUBLIC_URL + `/team-details/${slugify(data.title)}`}>
            <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} />
          </Link>
        </div>
        <div className="content">
          <h4 className="title">
            <Link to={process.env.PUBLIC_URL + `/team-details/${slugify(data.title)}`}>
              {data.title}
            </Link>
          </h4>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
      <div className="container">
        <div className="section-heading heading-light">
          <h2 className="title mb--50">Vårt Team</h2>
          <p>Møt folkene bak Nionor Software AS</p>
        </div>
        <div className="row">
            {teamItems}
        </div>
      </div>
    </div>
  );
}

export default TeamOne;
