import React from 'react';
import {useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import TeamBanner from '../component/team/TeamBanner';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import TeamData from "../data/team/TeamData.json";
import { slugify } from '../utils';

const allData = TeamData;


const TeamDetails = () => {

    const params = useParams();
    const teamSlug = params.slug;

    const getTeamData = allData.filter(data => slugify(data.title) === teamSlug);
    const detailsTeam = getTeamData[0];

    return (
        <>
        <SEO title="Team" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <TeamBanner
                 title={detailsTeam.title}
                 page="Om oss"
                 designation={detailsTeam.designation}
                 mainThumb={process.env.PUBLIC_URL + detailsTeam.thumb}
                 paragraph={detailsTeam.description}
                 email={detailsTeam.email}
                />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TeamDetails;