import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ContactBanner = () => {
    return (
        <div className="banner banner-style-sec ">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">Kontakt</h1>
                                <span className="subtitle">Kontakt oss for en uforpliktende samtale!</span>
                            </AnimationOnScroll>
                        </div>
                    </div>
                   
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-info mb--30" style={{
                            background: 'rgba(255, 255, 255, 0.03)',
                            borderRadius: 16,
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                            backdropFilter: 'blur(5.1px)',
                            WebkitBackdropFilter: 'blur(5.1px)',
                            position: 'relative', // Set the position to relative for the icon to be positioned relative to this container
                        }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/logo/icon.png"}
                            alt="Nionor"
                            style={{
                                width: 100,
                                position: 'absolute', 
                                top: 10, 
                                right: 10, 
                            }}
                            />
                            <h4 className="title" >Epost</h4>
                            <h1 className="phone-number" ><a href="mailto:hei@nionor.no">Hei@nionor.no</a></h1>
                        </div>
                    </div>

                   

                </div>
            </div>
            
        </div>
    )
}

export default ContactBanner;
