import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {

    return (
            <div className="section section-padding case-study-featured-area ">
                <div className="container dark-bg">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/banner/jonascoding.jpg"} alt="Jonas på tastaturet"  style={{ maxHeight: '800px', borderRadius: 20 }}/>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Hvem er vi?</span>
                            <h2 className="title">To Nørds</h2>
                            <p>Vi er to tech nerds med ulik bakgrunn innen programmering. Erfarne i både store backend systemer og frontend miljø. Vi utvikler programvare, nettsider og integrasjoner for SMB markedet.  </p>
                            
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="4" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Kopper kaffi drukket hver dag ;)</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="400" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Commits det siste året.</span>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;