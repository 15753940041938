import React from 'react';
import { Link } from 'react-router-dom';


const CtaLayoutOne = () => {

    return (

        <div className="section call-to-action-area" style={{marginBottom: 10}}>
            <div className="container">
                <div className="call-to-action">
                    <div className="section-heading heading-light">
                        <h2 className="title">Trenger du hjelp til et prosjekt?</h2>
                        <Link to={process.env.PUBLIC_URL + "/kontakt"} className="axil-btn btn-large btn-fill-white">
                            Ta kontakt
                        </Link>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-9">
               
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/logo/icon.png"} alt="Comments" style={{ width: 50 }} /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/logo/icon-white.png"} alt="Comments" style={{ width: 50 }} /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/logo/icon-white.png"} alt="Comments" style={{ width: 40 }} /></li>
                <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/logo/icon.png"} alt="Comments" style={{ width: 40 }} /></li>
                <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/logo/icon-white.png"} alt="Comments" style={{ width: 70 }} /></li>
                <li className="shape shape-7"><img src={process.env.PUBLIC_URL + "/images/logo/icon.png"} alt="Comments"  style={{ width: 40 }}/></li>
            </ul>
        </div>
    )

}

export default CtaLayoutOne;