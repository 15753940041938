import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = ( {readmore}) => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/project/nionor-preview.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Responsivt design</span>
                        <h2>Mobilvennlige nettsider som imponerer.</h2>  
                        <p>Ta steget inn i den digitale fremtiden med våre innovative mobilvennlige nettsider. Fra responsivt design til brukervennlighet – vi leverer løsninger som gjør din bedrift synlig og konkurransedyktig online.</p>
                        {readmore === true && (
                            <Link to={process.env.PUBLIC_URL + "/webutvikling"} className="jsj-btn">Les mer</Link>
                        )}
                        
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;