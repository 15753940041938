import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerOne from '../component/banner/BannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';


import TeamOne from '../component/team/TeamOne';

import ProjectFour from '../component/project/ProjectFour';

const DigitalAgency = () => {

    return (
        <>
        <SEO title="Webutvikling"/>
        
        <main className="main-wrapper">
     
            <HeaderOne />
            <BannerOne />
            <AboutFour />
            <ProjectFour />
            <AboutFive readmore={true} />
            
            
            <TeamOne />
            <CtaLayoutOne /> 

        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

