import React from 'react';


const FooterTwo = () => {

    return (
        <footer className="footer-area footer-dark">
            <div className="container">
                <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-6">
                    <div className="footer-copyright">
                        <span className="copyright-text">© {new Date().getFullYear()}. Alle rettigheter reservert <a href="https://nionorsoftware.no/">Nionor Software AS</a>. ORG NR: 931 603 523</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterTwo;