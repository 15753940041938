import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import AboutFour from '../component/about/AboutFour';
import TeamOne from '../component/team/TeamOne';


const AboutUs = () => {

    return (
        <>
        <SEO title="Om oss" />
       
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerTwo 
                title="Vi utvikler nettsider, programvare og annet kult."
                
                styleClass="thumbnail-4"
                mainThumb="/images/logo/icon.png"
                />
                <AboutFour />
                <TeamOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;