import React from 'react';
import SEO from '../common/SEO';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import ContactBanner from '../elements/breadcrumb/ContactBanner';


const Contact = () => {
    return (
        <>
            <SEO title="Kontakt" />
    
            <main className="main-wrapper">
                <HeaderOne />
                <ContactBanner
                />


            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;