import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Personvernerklæring" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Personvernerklæring"
                page="Personvernerklæring"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">Sist oppdatert 21 Juni, 2023.</h5>
                                </div>
                                <h4>Kontaktinformasjon til Nionor Software AS</h4>
                                <p>
                                Nionor Software AS er behandlingsansvarlig for håndtering av dine personopplysninger. Har du spørsmål om behandlingen eller ønsker å komme i kontakt med oss for å utøve dine rettigheter, finner du kontaktinformasjonen vår nedenfor:
                                <br></br><br></br>
                                Nionor Software AS<br></br>
                                Organisasjonsnummer: 931603523<br></br>
                                E-post adresse: support@nionor.no

                                </p>
                                <h4> Sentrale begreper</h4>
                                <p>Personopplysninger er informasjon som alene eller sammen med andre opplysninger kan brukes til å identifisere, lokalisere eller kontakte en person. Eksempler på personopplysninger er navn, telefonnummer og IP-adresse.
                                <br></br><br></br>
                                    Behandling av personopplysninger innebærer alle former for håndtering av personopplysninger som: innsamling, analyse, registrering og lagring.
                                    <br></br><br></br>
                                    Behandlingsansvarlig er den som bestemmer formålet med behandlingen av personopplysninger og hvilke opplysninger som anmodes. Det er behandlingsansvarlig som er ansvarlig for at håndteringen av dine personopplysninger skjer ut i fra den gjeldende personopplysningsloven. </p>
                                <h4>Hva slags informasjon er det vi samler inn?</h4>
                                <p>Når du registrerer deg via et skjema, bestiller en tjenste eller produkt, benytter deg av vår chat-funksjon eller besøker vår hjemmeside, kan du bli bedt om å gi fra deg informasjon. Avhengig av situasjonen kan vi muligens be om følgende personopplysninger:</p>
                                <ul>
                                    <li>Navn</li>
                                    <li>E-postadresse</li>
                                    <li>Telefonnummer</li>
                                    <li>Hvilken virksomhet du jobber for</li>
                                    <li>Stilling eller ansvarsområde</li>
                                    <li>Hvilken bransje du jobber i</li>
                                    <li>Hjemmesideadresse til virksomheten din</li>
                                    <li>Andre opplysninger som utdypende spørsmål eller svar på skjemaer</li>
                                    <li>Tekniske opplysninger: hvilken nettadresse du benytter for å få tilgang til våre nettsider, din IP-adresse og brukeradferd, type nettleser, språk og informasjon om identifisering og operativsystem.</li>
                                </ul>
                                <h4>Paragraf 6 f som alternativ til valgt samtykke via nettisde. Samtykke kan trekkes</h4>
                                <p className="mb--20">Vi tar utgangspunkt i legitimt samtykke utifra Paragraf 6f i personvernloven. Hvis du har inngått et kundeforhold med oss tidligere, eller du er en ny kunde vil dette være en legitim grunn til å sende deg informasjon. Dette sidestilles med et samtykke i henhold til markedsføringsloven slik loven har vært i Norge i mange år.  Du kan når som helst trekke dette samtykket om at vi skal behandle dine personopplysninger. Trekker du tilbake samtykket, vil vi ta bort de opplysningene vi har om deg. Merk at sletting av informasjonen hos oss kan påvirke leveransen av informasjon innenfor rammen for kunderelasjonen, samt påvirke pågående dialoger.</p>

                                <h4>Informasjon fra andre kilder</h4>
                                <p>Når du samtykker til at vi behandler dine personopplysninger godkjenner du også at vi kan registrere andre opplysninger om deg som du har gitt oss ved en tidligere anledning. Basert på offentlig tilgjengelig informasjon kan vi også supplere dine registrerte opplysninger med bransje og ytterligere kontaktinformasjon.  Hvis du er kunde hos oss kan vi også tilføye ytterligere kontaktinformasjon til dine opplysninger, som du har registrert hos oss via f.eks. telefon til kundeservice. Opplysninger som er nødvendige for de tjenester du skal bruke vil også bli lagret.</p>
                                <h4>Formålet med behandlingen av personopplysningene?</h4>
                                <p>Informasjonen som du gir fra deg brukes til følgende formål:
<ul>
<li>Salg- og markedsføringsaktiviteter i form av direkte e-post eller telefonkontakt</li>
<li>Kundebehandling og informasjon om våre produkter</li>
<li>For å få statistikk og opplysninger om brukeratferd for å forbedre både hjemmesiden og selve brukeropplevelsen</li>
<li>Slik at vi kan gi deg en mer personlig opplevelse og levere produkter og annet innhold som interesserer deg</li>
</ul>
Vi ber om dine personopplysninger for å:
<ul>
<li>Svare på forespørsler</li>
<li>Sende ønsket materiale, eller på andre måter kunne oppfylle våre forpliktelser mot at du sender inn dine opplysninger</li>
<li>Opprette og opprettholde en salgsdialog</li>
<li>Sende informasjon som kan være av interesse for deg</li>
<li>Legge deg til i e-post-liste for nyheter og annet innhold som du selv har valgt å motta</li>
</ul>
</p>
                                <h4>Samtykke til e-postkorrespondanse, direkte markedsføring og videre kontakt</h4>
                                <p className="mb--20">Når du samtykker til at vi behandler dine personopplysninger i overensstemmelse med de overnevnte formål, samtykker du til følgende:</p>                              <p className="mb--20">may log information using digital images called web beacons on our Site or in our emails.</p>
                                <p><ul>
                                    <li>Vi behandler dine personopplysninger i henhold til denne personvernerklæringen</li>
                                    <li>Vi kan sende deg direkte markedsføring via e-post om våre produkter</li>
                                    <li>Vi kan kontakte deg via e-post eller telefon</li>
                                    <li>Abonnering på nyhetsbrev via epost du selv har valgt å motta. Du kan takke nei til ytterligere e-poster ved å følge linken som befinner seg nederst i våre e-poster, eller ved å kontakte oss direkte.</li></ul></p>
                                <h4>Hvor lenge lagres opplysningene?</h4>
                                <p className="mb--20">Vi behandler personopplysningene bare så lenge det tar å oppfylle hensikten med deres innsamling, deretter sletter vi informasjonen.
<br></br><br></br>
Har du en aktiv dialog med oss tar vi vare på opplysningene dine i 2 år fra den siste kontakten; deretter fjerner vi opplysningene vi har om deg. En aktiv dialog defineres som at du har hatt interaksjon med Romerike Internett eller representanter for oss de siste  2 årene via telefon, ved å besvare e-post, lastet ned materiale på nettsiden eller registrert deg via et skjema.
<br></br><br></br>
Har du samtykket til behandling av dine personopplysninger i forbindelse med at du takket ja til regelmessig e-poster, fortsetter vi å behandle dine personopplysninger til du avslutter ditt abonnement. Deretter lagrer vi dine personopplysninger i 2 år før vi sletter informasjonen.
<br></br><br></br>
I det tilfellet du er ansatt i en virksomhet som er kunde hos oss, behandler vi dine opplysninger innenfor rammen for kundebehandling. For aktive kunderelasjoner behandler vi dine opplysninger inntil (1) du avslutter din stilling i virksomheten, eller at (2) virksomheten ikke lenger har en aktiv kunderelasjon med oss. Når en kunderelasjon avsluttes, går vilkårene for lagring og behandling av opplysninger over til de samme vilkårene som er beskrevet i de foregående avsnittene. Avslutter du din ansettelse i virksomheten, er du selv ansvarlig for å gi oss beskjed slik at vi kan slette dine opplysninger..</p>
                                
                                <h4>Hvem kan informasjonen deles med?</h4>
                                <p>Oppgitte opplysninger vil være tilgjengelige for alle ansatte i Nionor Software AS</p>
                                <h4>Hvor oppbevares opplysningene?</h4>
                                <p>Data lagres på Nionor Software sine egne systemer og servere, plassert i Norge. Vi forbeholder oss imildertidig retten til å lagre data på leverandører utenfor Norge, så lenge disse er innafor Norsk lov.</p>
                                <h4>Dine rettigheter iforhold til innsyn , sletting og eventuelt flytting</h4>
                                <p>Du har rett til å få informasjon om hvilke opplysninger vi har om deg. Du kan også kreve at vi retter opp i feilaktige opplysninger eller sletter informasjonen din. .
<br></br><br></br>
Ønsker du å trekke tilbake ditt samtykke eller kreve å få oversikt over opplysninger, rettelse eller sletting, kontakter du oss på e-postadressen vi har angitt under avsnittet for kontaktinformasjon. For å motta slik info må du sende en elektronisk kopi av forespørselen på et signert dokument. Ønsker du å flytte informasjon kan vi også hjelpe deg med det </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;