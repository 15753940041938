import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + "/"}>Hjem</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/webutvikling"}>Nettsider</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/kunnskapsbase"}>Kunnskapsbasen</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/kontakt"}>Kontakt</Link></li>
                            <li><a href="https://portal.nionor.com" target="_blank">Kundeportal</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Kontakt informasjon</span>
                                    <p>Hei@nionor.no</p>
                                    <p>Support@nionor.no</p>
                                    <p>Organisasjonsnummer: 931 603 523</p>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
