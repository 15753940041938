import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowUp } from "react-icons/fa";

const FooterOne = ({parentClass}) => { 
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
            
                <div className="footer-main">
                <p id="toTopBTN" class="axil-btn">Tilbake til topps! <FaArrowUp/></p>
                    <div className="row">
                        <div className="col-xl-6 col-lg-5" >
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Kontakt oss!</h2>
                                    <p>Vi svarer deg så fort som mulig.</p>
                                    <h4 className="title">Hei@nionor.no</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">

                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li>support@nionor.no</li>
                                                <li><Link to={process.env.PUBLIC_URL + "/kontakt"}>Kontakt oss</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Personvernerklæring</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/lisenser"}>Lisenser</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()} <a href="https://nionor.no">Nionor Software AS</a>. ORG NR: 931 603 523</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Personvernerklæring</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;