import React from 'react';
import { Link, useLocation } from 'react-router-dom';


const Nav = () => {
    const location = useLocation();

    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/"} className={location.pathname === "/" ? "active" : ""}>Hjem</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/webutvikling"} className={location.pathname === "/webutvikling" ? "active" : ""}>Nettsider</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/kunnskapsbase"} className={location.pathname === "/kunnskapsbase" ? "active" : ""}>Kunnskapsbasen</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/kontakt"} className={location.pathname === "/kontakt" ? "active" : ""}>Kontakt</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;