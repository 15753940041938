import React from 'react';



const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">Eksporter hele regnskapet, helt automatisk!</h1>
                    <p>Vi har utviklet en integrasjon som eksporter din regnskapsdata fra tripletex, helt automagisk!</p>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                    <img src={process.env.PUBLIC_URL + "/images/integrasjoner/tripletex-logo.png"} alt="Bubble" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFour;