import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FeatureData from '../data/tripletex/features.json';

import BannerFour from '../component/banner/BannerFour';
const TripletexIntegration = () => {
    return (
        <>
        <SEO title="Tripletex Integrasjon" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            
        <BannerFour />
            <div className="section section-padding bg-color-dark splash-features" id="splsh-features">
                
                    <div className="container">
                        <div className="row">
                            {
                                FeatureData.slice(0, 9).map((data) => (
                                    <div className="col-xl-4 col-md-6" key={data.id}>
                                        <div className="services-grid">
                                            <div className="thumbnail">
                                                <img src={process.env.PUBLIC_URL + data.icon} alt="icon" />
                                            </div>
                                            <div className="content">
                                                <h5 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h5>
                                                <p>{data.para}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
                    </ul>
                </div>
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default TripletexIntegration;