import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const BcrumbBannerTwo = ({title, paragraph, mainThumb}) => {
    return (
        <div className="banner banner-style-1 ">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">{title}</h1>
        
                            </AnimationOnScroll>
                        </div>
                    </div>
                    {mainThumb && (
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="zoomIn" duration={2} delay={300} animateOnce={true}>
                                <div className="large-thumb">
                                    <img src={mainThumb} alt="Programmering" style={{ width: 900, marginTop: -68 }} />
                                </div>
                            </AnimationOnScroll>

                        </div>
                    </div>
                    )}

                </div>
            </div>
            
        </div>
    )
}

export default BcrumbBannerTwo;
