import React, { useState } from 'react';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderThree from '../common/header/HeaderThree';
import SEO from '../common/SEO';
import SectionTitle from '../elements/section-title/SectionTitle';
import LicensesData from '../data/licenses/LicensesData.json';

const Licenses = () => {
    const [searchQuery, setSearchQuery] = useState('');

    let licensesItems = LicensesData.filter((data) =>
        data.name.toLowerCase().includes(searchQuery.toLowerCase())
        ).map((data) => (
        <tr key={data.id}>
            <td>
                <a href={data.url} target="_blank">
                    {data.name}
                </a>
            </td>
            <td>{data.type}</td>
            <td>{data.author}</td>
            <td>{data.project}</td>
        </tr>
    ));

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <SEO title="Lisenser" />
          
            <main className="main-wrapper">
                <HeaderThree />

                <div className="onepage-screen-area"   style={{ backgroundImage: 'url(/images/background2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container">
                    
                        <SectionTitle
                            subtitle="Bibloteker, illustrajoner og bilder vi benytter på tvers av våre systemer finner du her!"
                            textColor="#ffffff"
                            title="Ja vi ❤️ åpen kildekode!"
                            description=""
                            textAlignment="heading-left"
                            
                        />

                        <div>
                            <div className="search-bar ">
                                <input
                                    type="text"
                                    placeholder="Søk..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="bg-dark "
                                    style={{border: 'none'}}
                                />
                            </div>
                            <br></br>
                            <div className="card bg-dark" style={{ borderRadius: 15, padding: 20, overflow: 'scroll', maxHeight: 400 }}>
                            <table className="table table-dark " >
                                <thead>
                                    <tr>
                                        <th>Navn</th>
                                        <th>Type</th>
                                        <th>Forfatter/Eier</th>
                                        <th>Prosjekt</th>
                                    </tr>
                                </thead>
                                <tbody>{licensesItems}</tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <FooterTwo />
        </>
    );
};

export default Licenses;
